import styled from 'styled-components'

const BrandLogo = styled.div`
  max-width: 10rem;
  max-height: 4rem;
  display: flex;
  flex-direction: column;
`

export default BrandLogo
