import React from 'react'

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M506 6c-8.1-8-21.2-8-29.3 0L6 476.8a20.7 20.7 0 1 0 29.2 29.2L506 35.3c8.1-8 8.1-21.2 0-29.2z"/>
    <path d="M506 476.7L35.2 6A20.7 20.7 0 1 0 6.1 35.3L476.7 506a20.6 20.6 0 0 0 29.2 0c8.1-8 8.1-21.1 0-29.2z"/>
  </svg>
)

export const FooterIcon = ({ isVisible }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458 294">
    <g fill="none" fillRule="nonzero" transform="rotate(165 241 148.6)">
      <path id="animate" fill={isVisible ? '#d64505' : 'transparent'} d="M17 330.2l350.8 94a228.5 228.5 0 0 1-350.8-94zM.4 227.4a228.4 228.4 0 1 1 433.2 116L.4 227.5z"/>
      <g fill="#F0F7F7">
        <path d="M218.2 215.8c3 13.7-12.3 28.2-34.2 32.4-22 4.2-132.4 13.7-135.4 0S151 202.7 173 198.6c22-4.2 42.2 3.5 45.2 17.2zM244 208.7c-10 11.3-3.7 33.3 14.2 49.3 17.9 15.9 114 85 124 73.7 10-11.3-69.8-98.7-87.7-114.6-17.9-15.9-40.5-19.6-50.5-8.4z"/>
      </g>
      <path fill="#FFF" d="M324.8 359c0 19.5-15.9 124.6-35.4 124.6S254.1 378.5 254.1 359a35.3 35.3 0 0 1 70.7 0z"/>
      <path fill="#CE1111" d="M297 405.2c-18 4.1-42-5.7-68.7-5.7-47 0-86.2 30.6-96.8-38.6L297 405.2zM136.6 264c15.2-46.7 50.6-79.4 91.7-79.4 55 0 99.6 58.5 99.6 130.7l-191.3-51.3z"/>
      <path fill="#F0F7F7" d="M335.8 317.3l-205.2-55c17.7-45.9 57-77.8 97.7-77.8 55 0 107.5 58.5 107.5 130.7v2zm-19.8 93C295.8 440 262.5 439 228.3 439c-47.8 0-93.7 2-105-80.2L316 410.3z"/>
      <g fill="#FFF">
        <path d="M327.4 315l-190.7-51c15.2-46.8 50.5-79.5 91.6-79.5 53.3 0 96.7 55 99.4 124l-.3 6.5zm-124 65.1c-29.5 4.6-53.6 12.2-66-17.6l66 17.6z"/>
        <path d="M357.2 124.3c0 63-57.7 94.6-128.9 94.6s-129-31.6-129-94.6c0-63 57.8-124.3 129-124.3s129 61.2 129 124.3z"/>
      </g>
      <path fill="#113E49" d="M169.1 89.6h127v4.6h-127z"/>
      <path fill="#F0F7F7" d="M357.2 124.3c0 63-57.7 94.6-128.9 94.6s-129-31.6-129-94.6"/>
      <circle cx="167" cy="92.2" r="11.5" fill="#113E49"/>
      <path fill="#000" d="M178.5 92.2a11.5 11.5 0 1 1-23 0"/>
      <path fill="#F0F7F7" d="M189.7 116.3c-.6 0-1.2-.3-1.7-.8a29.5 29.5 0 0 0-42 0c-1 1-2.6 1-3.5 0-1-.9-1-2.4 0-3.4a34.4 34.4 0 0 1 49 0 2.4 2.4 0 0 1-1.8 4.2z"/>
      <circle cx="289.7" cy="92.2" r="11.5" fill="#113E49"/>
      <path fill="#000" d="M278 92.2a11.5 11.5 0 1 0 23.2 0"/>
      <path fill="#F0F7F7" d="M266.9 116.3c.6 0 1.2-.3 1.7-.8a29.5 29.5 0 0 1 42 0c1 1 2.5 1 3.5 0 1-.9 1-2.4 0-3.4a34.4 34.4 0 0 0-49 0c-.9 1-.9 2.5 0 3.4.5.5 1.1.8 1.8.8z"/>
    </g>
  </svg>
)
