import styled from 'styled-components'
import { Link } from 'components'

const Case = styled(Link)`
  display: block;
  position: relative;
  padding: 2em;
  /* border: 1px solid #333; */
  background-color: #333;
  border-radius: 0.3em;
  overflow: hidden;
  margin-bottom: 4em;
  h1 {
    margin: 2em 0;
  }
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
    color: #fff;
  }
`

export default Case
