import styled from "styled-components"

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  filter: ${({ dark }) => dark ? 'brightness(0.3) contrast(1)' : 'none'};

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    @media (max-width: 540px) {
      width: auto;
    }
  }
`

export default Background
