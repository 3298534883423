import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components'

const features = [
  {
    title: '100%',
    subtitle: 'Positive Feedbacks'
  },
  {
    title: '37',
    subtitle: 'Done Projects'
  },
  {
    title: '89%',
    subtitle: 'Clients Return'
  },
  {
    title: '0',
    subtitle: 'Unmet Deadlines'
  },
  {
    title: 'Everybody',
    subtitle: 'loves pizza'
  }
]

const Features = styled.div`
  margin-top: 4em;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2em;
  h2 {
    margin: 0;
    font-size: 2em;
  }
  @media (max-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 426px) {
    grid-template-columns: repeat(1, auto);
  }
`

export default () => {
  return (
    <>
      <h1>Team features</h1>
      <Features>
        {features && features.map(({ title, subtitle }) => (
          <Flex column key={title}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
          </Flex>
        ))}
      </Features>
    </>
  );
}
