import React from 'react'
import { Section, Container, Callback } from 'components'

class CallbackSection extends React.Component {
  render() {
    return (
      <Section blue>
        <Container>
          <Callback>
            <div>
              <h1>Leave your e-mail</h1>
            </div>
            <div>
              <h4>Get a news from us</h4>
            </div>
          </Callback>
        </Container>
      </Section>
    )
  }
}

export default CallbackSection
