import moment from 'moment'
import { injectIntl } from 'gatsby-plugin-intl'
import * as Yup from 'yup'
import { formatMessage, FormattedMessage } from 'components'

const windowGlobal = typeof window !== 'undefined' && window

const menu = [
  {
    title: `portfolio`,
    to: '/portfolio',
  },
  {
    title: 'technologies',
    to: '/technologies',
  },
  // {
  //   title: 'blog',
  //   to: '/blog',
  // },
  {
    title: 'about',
    to: '/about',
  },
  {
    title: 'contacts',
    to: '/contacts',
  },
]

const languageName = {
  en: 'English',
  ru: 'Русский',
}

const filterLocale = ({ node }) => node.node_locale === 'en'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const ContactUsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  message: Yup.string()
    .required('Required')
})



export { injectIntl, moment, windowGlobal, menu, languageName, filterLocale, ContactUsSchema }
