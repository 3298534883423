import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl, filterLocale } from 'helpers'
import { Link, Flex } from 'components'

const Cases = styled.div`
  margin-top: 4em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
  h2 {
    margin: 0;
    font-size: 2em;
  }
  @media (max-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 426px) {
    grid-template-columns: repeat(1, auto);
  }
`

export default injectIntl(({ intl: { locale } }) => {
  const { cases } = useStaticQuery(graphql`
    {
      cases: allContentfulCase {
        edges {
          node {
            id
            slug
            title
            date
            node_locale
          }
        }
      }
    }
  `)
  return (
    <>
      <Cases>
        {cases &&
          cases.edges
            .filter(({ node }) => node.node_locale === locale)
            .map(({ node: { id, slug, title, date } }) => (
              <Link to={`/portfolio/${slug}`} key={id}>
                <Flex column>
                  <h2>{title}</h2>
                  <h3>{date}</h3>
                </Flex>
              </Link>
            ))}
      </Cases>
    </>
  )
})
