import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { ContactUsSchema } from 'helpers'
import { Flex, Button } from 'components'

const contactFormFields = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'email',
    label: 'E-mail',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
]

const TextWrapper = styled(Flex)`
  /* margin: 1em 0; */
  label {
    display: flex;
    flex-direction: column;
  }
  ${({ full }) =>
    full
      ? `
    grid-column-start: 1;
    grid-column-end: 3;
  `
      : ''}
`

const Text = ({ name, label, full, ...props }) => (
  <TextWrapper full={full} column>
    <label htmlFor={name || label}>
      <span>{label || name}</span>
      <Field name={name} {...props} />
    </label>
    <ErrorMessageWrapper name={name} />
  </TextWrapper>
)

const ErrorMessageWrapper = styled(props => (
  <p className="error">
    <ErrorMessage {...props} />
  </p>
))`
  position: relative;
  color: red;
`

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1em 2em;
  margin-bottom: 3em;
`

const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }}
      validationSchema={ContactUsSchema}
      onSubmit={values => {
        // same shape as initial values
        console.log(values)
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <ContactGrid>
            {contactFormFields &&
              contactFormFields.map(item => <Text key={item.name} {...item} />)}
            <Text full component="textarea" name="message" label="Message" />
          </ContactGrid>
          <Button light type="submit">
            Send request
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
