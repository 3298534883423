import React, { Component } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import { menu, windowGlobal, languageName, injectIntl } from 'helpers'
import {
  Container,
  Link,
  Logo,
  LogoMiniCB,
  IntlContextConsumer,
  FormattedMessage,
} from 'components'
import { FaLanguage } from 'react-icons/fa'

const Hamburger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6em;
  height: 1em;
  padding: 1.6em 0;

  & > span {
    height: 3px;
    background: #fff;
    transition: all 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  & > span:nth-child(1) {
    transform-origin: top right;
    transform: ${({ open }) =>
      open ? 'translateY(0em) translateX(-.25em) rotate(-45deg)' : ''};
  }
  & > span:nth-child(2) {
    transform-origin: top left;
    transform: ${({ open }) =>
      open ? 'translateY(-.5em) translateX(.3em) rotate(45deg)' : ''};
  }
`

const MenuButton = styled.div`
  float: right;
  cursor: pointer;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`

const Header = styled.div`
  position: fixed;
  width: 100vw;
  height: 5em;
  top: 0;
  left: 0;
  z-index: 100;
  background: #141414
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90%);
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  transform: translate3d(0, ${({ downDir }) => (downDir ? -5 : 0)}em, 0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
`

const LogoWrapper = styled.div`
  position: fixed;
  width: 8em;
  display: flex;
  align-items: center;
  padding: 1.7em 1em;
  transform: translate3d(0, ${({ downDir }) => (downDir ? 5 : 0)}em, 0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  a {
    height: 1.4em;
  }

  svg {
    width: 100%;
    fill: #fff;
  }

  .logo-mini {
    display: none;
  }
  .logo {
    display: block;
  }

  @media (max-width: 1400px) and (min-width: 768px) {
    width: 2em;
    .logo-mini {
      display: block;
    }
    .logo {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding: 1.6em 5vw;
    transform: none;
  }
`

const MenuWrapper = styled.nav`
  height: 100%;
`

const Menu = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #141414;
    position: fixed;
    top: 4.5em;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: calc(100vh - 4.5em);
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? 1 : 0)};
    transform: translate3d(0, ${({ open }) => (open ? 0 : -50)}px, 0);
    transition: all 0.3s ease;
  }
`

const MenuItem = styled.li`
  display: flex;
  margin-right: 2em;
  a {
    position: relative;
    transition: all 0.3s ease;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background: #fff;
      bottom: 0;
      transform: translateX(-50%) scaleX(0);
      transition: all 0.3s ease;
    }
    &:hover {
      color: #fff;
      &:before {
        transform: translateX(0%) scaleX(1);
        transition: all 0.3s ease;
      }
    }
    &.active {
      color: #fff;
      &::before {
        transform: translateX(0%) scaleX(1);
        transition: all 0.3s ease;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0.5em;
    font-size: 1.4em;
    margin-right: 0;
  }
`

const ChangeLanguageWrapper = styled(MenuItem)`
  position: absolute;
  top: 1.8rem;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    opacity: 1;
    color: #fff;
  }
  svg {
    padding-right: 0.5em;
  }
`

const ChangeLanguageWrapperItem = styled.div`
  display: flex;
  align-items: center;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  user-select: none;

  @media (max-width: 767px) {
    visibility: visible;
    opacity: 1;
  }
`

class ChangeLanguage extends Component {
  state = {
    open: false,
  }
  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    let { open } = this.state
    return (
      <ChangeLanguageWrapper onClick={this.toggleOpen} title="change lang">
        <FaLanguage size="1.5em" />
        {!open && (
          <small>
            {' '}
            <FormattedMessage id={'switch-lang'} />
          </small>
        )}
        <ChangeLanguageWrapperItem open={open}>
          <IntlContextConsumer>
            {({ language: origLanguage, languages, originalPath, ...props }) =>
              languages
                .filter(i => i !== origLanguage)
                .map(language => (
                  <Link
                    key={language}
                    language={language}
                    to={originalPath}
                    hrefLang={language}
                  >
                    <small>{languageName[language]}</small>
                  </Link>
                ))
            }
          </IntlContextConsumer>
        </ChangeLanguageWrapperItem>
      </ChangeLanguageWrapper>
    )
  }
}

const HrefLangs = injectIntl(({ intl: { locale, ...props } }) => {
  return (
    <IntlContextConsumer>
      {({ languages, originalPath, ...props }) =>
        languages.map(language => (
          <link
            key={language}
            rel="alternate"
            href={originalPath}
            hrefLang={language}
          />
        ))
      }
    </IntlContextConsumer>
  )
})

export default class extends Component {
  state = {
    open: false,
    downDir: false,
    y: 0,
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window && window.addEventListener('wheel', this.wheelDetect)
      window && window.addEventListener('scroll', this.scrollDetect)
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window && window.removeEventListener('wheel', this.wheelDetect)
      window && window.removeEventListener('scroll', this.scrollDetect)
    }
  }

  scrollDetect = () => {
    let supportPageOffset = window.pageXOffset !== undefined
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
    let y = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop

    this.setState({ y })
  }

  wheelDetect = event => {
    if (typeof window !== `undefined`) {
      let supportPageOffset = window.pageXOffset !== undefined
      let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
      let y = supportPageOffset
        ? window.pageYOffset
        : isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop

      var delta = event.wheelDelta ? event.wheelDelta : -1 * event.deltaY
      if (y < 10) {
        delta = 1
      }
      this.setState({
        downDir: delta < 0 ? true : false,
      })
    }
  }

  checkScoll = () => {
    if (windowGlobal) {
      let elem = document.getElementsByTagName('body')[0]
      if (this.state.open) {
        elem.classList.add('no-scroll')
      } else {
        elem.classList.remove('no-scroll')
      }
    }
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  closeMenu = () => {
    if (this.state.open) {
      this.toggleMenu()
    }
  }

  render() {
    let { open, downDir, y } = this.state
    this.checkScoll()
    return (
      <Header downDir={!open && downDir}>
        {/*
          <Helmet>
            <HrefLangs />
            <link
              rel="alternate"
              href={'originalPath'}
              hrefLang={'ru'}
            />
            <link
              rel="alternate"
              hreflang="x-default"
              href="https://www.example.com/"
            />
          </Helmet>
          */}
        <LogoWrapper downDir={downDir} onClick={this.closeMenu}>
          <Link to="/">
            <Logo />
            <LogoMiniCB />
          </Link>
        </LogoWrapper>
        <Container>
          <MenuButton onClick={this.toggleMenu}>
            <Hamburger open={open}>
              <span />
              <span />
            </Hamburger>
          </MenuButton>
          <MenuWrapper>
            <Menu open={open}>
              {menu &&
                menu.map(({ to, title }) => (
                  <MenuItem key={to}>
                    <Link to={to}>
                      <FormattedMessage id={title} />
                    </Link>
                  </MenuItem>
                ))}
            </Menu>
          </MenuWrapper>
          <ChangeLanguage />
        </Container>
      </Header>
    )
  }
}
