import React from 'react'
import styled from 'styled-components'
import { Link, Flex } from 'components'

const offers = [
  {
    title: '01',
    subtitle: 'UX / UI Design',
    description: 'Professionally developed design will result not only in admiration of the users, but also increase your analytical and marketing perfomance'
  },
  {
    title: '02',
    subtitle: 'Web Development',
    description: 'Either you want a landing or a huge e-commerce platform, our web experts will develop the best solution according to the latest tendencies'
  },
  {
    title: '03',
    subtitle: 'Quality Assurance',
    description: 'Once the product is developed, we will test it in different environments for detecting and fixing bugs for creating a high-level product'
  },
  {
    title: '04',
    subtitle: 'Sicilian Pizza',
    description: 'Professionally developed design will result not only in admiration of the users, but also increase your analytical and marketing perfomance'
  }
]

const WeOffer = styled.div`
  margin: 4em 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4em;
  h2 {
    margin: 0;
    font-size: 2em;
  }
  h3 {
    margin: 0;
  }
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export default () => {
  return (
    <>
      <h1>What we can offer you</h1>
      <WeOffer>
        {offers && offers.map(({ title, subtitle, description }) => (
          <Flex column key={title}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            <p>{description}</p>
          </Flex>
        ))}
      </WeOffer>
      <Link to="/technologies">See our technologies</Link>
    </>
  );
}
