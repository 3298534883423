import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import PageWrapper from 'components/page-wrapper'
import styled, { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import globalCss from 'global.css'

import { injectIntl } from 'helpers'

import {
  Section,
  Container,
  Flex,
  Header,
  Footer,
  Link,
  SocialLinks,
} from 'components'
import { CloseIcon } from 'components/icons'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${globalCss}
`

const Spacer = styled.div`
  height: 5em;
`

const CloseButton = styled(Flex)`
  z-index: 10;
  position: fixed;
  top: 1.5em;
  right: 3.5em;
  width: 2.1em;
  height: 2.1em;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  svg {
    width: 0.6em;
    height: 0.6em;
    fill: #fff;
  }
  @media (max-width: 767px) {
    right: 1.5em;
  }
`

const Layout = ({ children, simple, intl: { locale }, ...props }) => (
  <>
    <GlobalStyle />
    <Helmet title={`codeburg`}>
      <html lang={locale} />
    </Helmet>
    {!simple && (
      <>
        <Header />
        <Spacer />
      </>
    )}
    {children}
    {simple && (
      <Link to="/portfolio">
        <CloseButton center>
          <CloseIcon />
        </CloseButton>
      </Link>
    )}
    {!simple && <SocialLinks />}
    <Footer simple={simple} />
  </>
)

export default injectIntl(Layout)
