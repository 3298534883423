import styled from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: ${({ nopad, fullpage }) =>
    nopad ? '0' : fullpage ? '10em 0' : '4em 0'};
  background-color: ${({ black, light }) =>
    black ? '#000' : light ? '#202121' : 'transparent'};
  min-height: ${({ fullpage }) => (fullpage ? 'calc(100vh - 25em)' : 'auto')};

  display: flex;
  align-items: center;

  overflow: hidden;
`

export default Section
