import React from 'react'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import { Link, Container } from 'components'
import { FooterIcon } from 'components/icons'

const Footer = styled.footer``

const Links = styled.ul`
  display: grid;
  grid-gap: 1em;
  grid-template: 'l l l u' / 1fr 1fr 1fr auto;

  > li {
    padding-top: 2em;
  }

  > div {
    grid-area: u;
  }

  @media (max-width: 700px) {
    grid-template:
      'u u'
      'l l' / 1fr 1fr;

    & > a {
      display: none;
    }
  }
`

const FooterIconWrapper = styled.div`
  width: 10em;
  position: relative;
  top: -0.2em;
  #animate {
    /* fill: transparent; */
    transition: all 3s ease;
  }
  /* &:hover {
    #animate {
      fill: #d64505;
    }
  } */
`

const Copyrights = styled.div`
  padding-top: 2em;
  text-align: center;
`

const FooterWrapper = () => (
  <Footer>
    <Container>
      <Links>
        <li>
          <h3>Contacts</h3>
          <p>
            <span>428000, Russia</span>
            <br />
            <span>tel. (8352) 27-53-93</span>
          </p>
          <h3>E-mail us</h3>
          <a href="mailto:275393@inbox.ru">275393@inbox.ru</a>
        </li>
        <li>
          <h3>Blog</h3>
          <ul>
            <li>
              <Link to="/">Войти/регистрация</Link>
            </li>
            <li>
              <Link to="/">Пользование</Link>
            </li>
            <li>
              <Link to="/">Возвраты</Link>
            </li>
          </ul>
        </li>
        <li>
          <h3>Social</h3>
          <ul>
            <li>Facebook</li>
            <li>Instagram</li>
            <li>Twitter</li>
          </ul>
        </li>
        <FooterIconWrapper>
          <VisibilitySensor>
            {({ isVisible }) => <FooterIcon isVisible={isVisible}  />}
          </VisibilitySensor>
        </FooterIconWrapper>
      </Links>
      <Copyrights>
        <h4>
          <Link to="/">codeburg</Link> 2019
        </h4>
      </Copyrights>
    </Container>
  </Footer>
)

export default FooterWrapper
