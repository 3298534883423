import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  max-width: 50%;

  @media (max-width: 1025px) {
    max-width: 100%;
  }
`

export default Box
