import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  border: 1px solid ${({ light }) => (light ? '#fff' : '#000')};
  border-radius: .2em;
  background: ${({ light }) => (light ? '#fff' : '#000')};
  padding: 1em 2em;
  color: ${({ light }) => (light ? '#000' : '#fff')};
`

export default Button
