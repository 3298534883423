import React from 'react'
import { IntlContextConsumer } from 'gatsby-plugin-intl'
import styled from 'styled-components'

import { Link } from 'components'
import { FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa'

const UpWorkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.7 56.7" width="100%">
    <path d="M42.4 17.7c-5.3 0-9.3 3.5-10.9 9a48.4 48.4 0 0 1-5.6-12.1h-5.6v14.7c0 2.9-2.4 5.3-5.3 5.3a5.3 5.3 0 0 1-5.3-5.3V14.7H4.3v14.7a11 11 0 0 0 10.9 11 11 11 0 0 0 10.9-11v-2.5c1.1 2.2 2.5 4.6 4 6.7l-3.5 16.3h5.7L34.7 38a14 14 0 0 0 7.7 2.2c6.1 0 11.1-5 11.1-11.4 0-6.1-5-11.1-11.1-11.1zm0 16.9c-2.2 0-4.5-1-6.3-2.5l.6-2.2v-.1c.4-2.4 1.7-6.4 5.8-6.4 3.1 0 5.6 2.5 5.6 5.6a5.9 5.9 0 0 1-5.7 5.6z" />
  </svg>
)

const SocialLinks = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    display: none;
  }
`

const SocialIcon = styled.div`
  padding: 0.5em 1em;
  svg {
    width: 1.2em;
    height: 1.2em;
    fill: #aaa;
    transition: all .3s ease;
    &:hover {
      fill: #fff;
    }
  }
`

export default () => (
  <SocialLinks>
    <SocialIcon>
      <Link to="/">
        <UpWorkIcon />
      </Link>
    </SocialIcon>
    <SocialIcon>
      <Link to="/">
        <FaInstagram />
      </Link>
    </SocialIcon>
    <SocialIcon>
      <Link to="/">
        <FaTwitter />
      </Link>
    </SocialIcon>
    <SocialIcon>
      <Link to="/">
        <FaLinkedin />
      </Link>
    </SocialIcon>
  </SocialLinks>
)
