import React from 'react'
import styled from 'styled-components'
// import { Link as GatsbyLink } from 'gatsby'
import { Link as GatsbyLink } from 'gatsby-plugin-intl'

const LinkWrapper = styled(GatsbyLink)`
  transition: all 0.3s ease;
  &:hover {
    /* color: #fff; */
  }
  /* position: relative;
  transition: all 0.3s ease;
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    bottom: 0;
    transform: translateX(-50%) scaleX(0);
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
    &:before {
      transform: translateX(0%) scaleX(1);
      transition: all 0.3s ease;
    }
  } */
`

const Link = ({ children, to, activeClassName, bold, underline, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <LinkWrapper
        to={`${to}/`}
        activeClassName={activeClassName || 'active'}
        {...other}
      >
        {bold ? <b>{children}</b> : underline ? <u>{children}</u> : children}
      </LinkWrapper>
    )
  }
  return (
    <a href={to} {...other} rel="nofollow noopener">
      {children}
    </a>
  )
}

export default Link
