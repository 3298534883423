import React, { Component } from 'react'
import styled from 'styled-components'

const CallbackGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5%;

  h1 {
    margin: 0;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 700px) {
    grid-template-columns: auto;
    grid-gap: 0;
  }
`

const CallbackForm = styled.form`
  display: flex;
  flex-direction: column;
`

const CallbackSubmit = styled.button`
  margin-top: -1px;
`

class Callback extends Component {
  render() {
    let { children } = this.props
    return (
      <CallbackGrid>
        {children}
        <CallbackForm>
          <input
            name="phone"
            placeholder="Ваша электронная почта"
            type="email"
          />
          <CallbackSubmit>Подписаться</CallbackSubmit>
        </CallbackForm>
      </CallbackGrid>
    )
  }
}

export default Callback
