import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  @media (min-width: 1750px) {
    width: 72vw;
  }
  @media (max-width: 1750px) {
    width: 76vw;
  }
  @media (max-width: 1400px) {
    width: 85vw;
  }
  @media (max-width: 767px) {
    width: 90vw;
  }
`

export default Container
