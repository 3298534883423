import React from 'react'
import GoogleMapReact from 'google-map-react'
import { injectIntl } from 'helpers'
import styles from './styles'

let settings = {
  defaultCenter: {
    lat: 56.108455,
    lng: 47.244747,
  },
  defaultZoom: 17,
  bootstrapURLKeys: {
    key: 'AIzaSyC-qpsIESUrYPNVlTkSu65v3UJ0S6J7eBQ',
  },
  options: {
    disableDefaultUI: true,
    draggable: true,
    keyboardShortcuts: false,
    scaleControl: false,
    styles: styles
  },
}

const Marker = ({ text }) => <h3>{text}</h3>

const GMap = () => (
  <div style={{ minHeight: '500px', height: '50vh', width: '100%' }}>
    <GoogleMapReact {...settings}>
      <Marker {...settings.defaultCenter} text="ООО Современные решения" />
    </GoogleMapReact>
  </div>
)

export default GMap
